import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { getSection } from '../drupalPagesGenerator/pageTypeToSection';

const IndexPage = ({ data }: any) => {
  const paragraphs = data.drupal.nodeQuery.entities[0].paragraphs.map(getSection);

  return (
    <Layout
      language={data.drupal.nodeQuery.entities[0].entityLanguage.id}
      pathEn={data.drupal.nodeQuery.entities[0].entityUrl.en.path}
      pathRo={data.drupal.nodeQuery.entities[0].entityUrl.ro.path}
      title=''
      metaTags={data.drupal.nodeQuery.entities[0].metaTags}
      ogImage={data.drupal.nodeQuery.entities[0].ogImage?.childImageSharp?.fluid?.src}
      type='landingPage'
    >
      {paragraphs}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($CurrentPage: Int = 0) {
    drupal {
      nodeQuery(
        filter: {
          conjunction: AND
          conditions: [
            { operator: EQUAL, field: "type", value: ["landing_page"] }
            { operator: EQUAL, field: "field_is_homepage", value: ["1"] }
          ]
        }
      ) {
        entities(language: RO) {
          ... on DRUPAL_NodeLandingPage {
            entityId
            entityLabel
            entityLanguage {
              id
            }
            entityUrl {
              en: translate(language: EN) {
                path
              }
              ro: translate(language: RO) {
                path
              }
            }
            fieldOgImage {
              alt
              entity {
                uri {
                  url
                }
              }
            }
            ogImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }
            }
            metaTags: entityMetatags {
              ... on DRUPAL_MetaValue {
                key
                value
              }
              ... on DRUPAL_MetaLinkHreflang {
                key
                value
              }
              ... on DRUPAL_MetaItemProp {
                key
                value
              }
              ... on DRUPAL_MetaLink {
                key
                value
              }
              ... on DRUPAL_MetaHttpEquiv {
                key
                value
              }
              ... on DRUPAL_MetaProperty {
                key
                value
              }
            }
            paragraphs: fieldParagraphs {
              entity {
                entityLanguage {
                  id
                }
                ...ParagraphGeneric
                ...ParagraphView
                ...ParagraphLongText
                ...ParagraphTestimonial
                ...ParagraphSingleImage
                ...ParagraphMultipleImages
                ...ParagraphContainer
              }
            }
          }
        }
      }
    }
  }
`;
